import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';

import { InvestmentCardConfigToken } from '../investment-card-config-token';
import type { InvestmentCardModuleConfig } from '../investment-card-config-token';
import type { InvestmentData } from '@equito/shared/models';

@Component({
  selector: 'equito-investment-card',
  templateUrl: './investment-card.component.html',
  styleUrls: ['./investment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentCardComponent {
  constructor(
    @Inject(InvestmentCardConfigToken)
    private config: InvestmentCardModuleConfig
  ) {}

  @Input() investment: InvestmentData;

  get link() {
    return ['/', this.config.investmentPath, this.investment.slug];
  }
}
