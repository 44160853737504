import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [CommonModule],
})
export class SharedUiMessageModule {
  static forRoot(): ModuleWithProviders<SharedUiMessageModule> {
    return {
      ngModule: SharedUiMessageModule,
      providers: [
        ...ToastrModule.forRoot({
          maxOpened: 3,
          autoDismiss: true,
        }).providers,
      ],
    };
  }
}
