import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private registry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  registerIcons() {
    this.registry.addSvgIcon(
      'plus',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/plus-icon.svg'
      )
    );
    this.registry.addSvgIcon(
      'minus',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/minus-icon.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-facebook',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/facebook-green.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-twitter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/twitter-green.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-linkedin',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/linkedin-green.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-instagram',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/instagram-green.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-checkmark',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/checkmark.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-content-copy',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/content_copy-24px.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-bank-account',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/bank-account.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-identity-card-success',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/identity-card.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-identity-card-danger',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/identity-card-red.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-identity-card-warning',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/identity-card-orange.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-help-sign',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/help_outline-24px.svg'
      )
    );
    this.registry.addSvgIcon(
      'eq-google-sso',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/google-sso.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-facebook-sso',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/facebook-sso.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-linkedin-sso',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/linkedin-sso.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-twitter-sso',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/twitter-sso.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-mastercard',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mastercard.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-visa',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/visa.svg')
    );

    this.registry.addSvgIcon(
      'eq-daterangepicker',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/date_range-24px.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-facebook-landing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/facebook_landing.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-instagram-landing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/instagram_landing.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-twitter-landing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/twitter_landing.svg'
      )
    );

    this.registry.addSvgIcon(
      'eq-linkedin-landing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/linkedin_landing.svg'
      )
    );
  }
}
