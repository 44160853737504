import { DOCUMENT } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Renderer2,
  OnInit,
  OnDestroy,
} from '@angular/core';
const scriptUrl = 'https://equito.activehosted.com/f/embed.php?id=5';
const scriptId = 'formid5';

@Component({
  selector: 'equito-active-campaign-footer-card',
  templateUrl: './active-campaign-footer-card.component.html',
  styleUrls: ['./active-campaign-footer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveCampaignFooterCardComponent implements OnInit, OnDestroy {
  waitForWidgetToFullyLoad;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.waitForWidgetToFullyLoad);
    const el = this.document.getElementById(scriptId);

    if (el) {
      this.renderer.removeChild(this.document, el);
    }
  }

  ngOnInit(): void {
    const el = this.renderer.createElement('script');
    this.renderer.setAttribute(el, 'type', 'text/javascript');
    this.renderer.setAttribute(el, 'src', scriptUrl);
    this.renderer.setAttribute(el, 'id', scriptId);
    this.renderer.setProperty(el, 'async', true);
    this.renderer.setProperty(el, 'defer', true);
    this.renderer.appendChild(this.document.body, el);

    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'stylesheet');
    this.renderer.setAttribute(
      link,
      'href',
      '/assets/css/active-campaign-footer.css'
    );
    this.renderer.setAttribute(link, 'tyle', 'text/css');
    this.renderer.setProperty(link, 'async', true);
    this.renderer.setProperty(link, 'defer', true);
    this.renderer.appendChild(this.document.head, link);

    this.waitForWidgetToFullyLoad = setInterval(() => {
      const widgetFrame = this.document.getElementById('equito-footer-ac');
      const form = widgetFrame?.getElementsByTagName('form')[0];

      if (form) {
        //removing css from AC
        const formWidget = this.document.getElementsByClassName(
          '_form_5'
        )[0] as HTMLElement;
        formWidget.children[0].remove();

        form.classList.add('ac-footer-form');

        const formTitle = form.getElementsByClassName(
          '_form-title'
        )[0] as HTMLElement;
        if (formTitle) {
          formTitle.classList.add('ac-footer-form-title');
        }

        const formContent = form.getElementsByClassName(
          '_form-content'
        )[0] as HTMLElement;
        if (formContent) {
          formContent.classList.add('ac-footer-form-content');
        }

        //removing unvisible label
        const label = formContent.getElementsByClassName('_form-label')[0];
        label.remove();

        const inputFormWrapperElement = formContent.children[1] as HTMLElement;
        if (inputFormWrapperElement) {
          inputFormWrapperElement.classList.add('ac-footer-form-wrapper-input');
        }

        const inputField = formContent.querySelector(
          'input[id="email"]'
        ) as HTMLInputElement;
        if (inputField) {
          inputField.setAttribute('autocomplete', 'off');
          inputField.classList.add('ac-footer-form-input');
        }

        const buttonFormWrapperElement = formContent.children[2] as HTMLElement;
        if (buttonFormWrapperElement) {
          buttonFormWrapperElement.classList.add(
            'ac-footer-form-wrapper-button'
          );
        }

        const button = formContent.getElementsByTagName(
          'button'
        )[0] as HTMLElement;

        const checkbox = this.document
          .getElementById('footerCheckbox')
          .querySelector('input[type="checkbox"]') as HTMLElement;

        if (button) {
          button.classList.add('btn');
          button.classList.add('btn--black-and-white');
          button.classList.add('ac-footer-form-button');
          button.setAttribute('disabled', 'true');

          button?.addEventListener('click', () => {
            const validateEmail = (email) => {
              return email.match(
                // eslint-disable-next-line
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
            };

            if (validateEmail(inputField.value)) {
              checkbox.parentElement.parentElement.parentElement.remove();
              form.style.cssText = 'padding: 32px 64px;';
            }
          });

          checkbox.addEventListener('change', () => {
            const checkboxStatus = checkbox.getAttribute('ng-reflect-model');
            if (checkboxStatus === 'true') {
              button.removeAttribute('disabled');
            } else {
              button.setAttribute('disabled', 'true');
            }
          });
        }

        const thanksForSigningUp = form.getElementsByClassName(
          '_form-thank-you'
        )[0] as HTMLElement;
        if (thanksForSigningUp) {
          thanksForSigningUp.classList.add('ac-footer-thank-you');
        }

        clearInterval(this.waitForWidgetToFullyLoad);
      }
    }, 25);
  }
}
