import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CrowdfundingUiPageShellModule } from '@equito/crowdfunding/ui/page-shell';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CrowdfundingUiInvestmentCardModule } from '@equito/crowdfunding/ui/investment-card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedUiMessageModule } from '@equito/shared/ui/message';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CrowdfundingUiPageShellModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        loadChildren: () =>
          import('@equito/crowdfunding/lazy/landing-page').then(
            (m) => m.CrowdfundingLazyLandingPageModule
          ),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]),
    CrowdfundingUiInvestmentCardModule.forRoot({
      investmentPath: 'investment',
    }),
    BrowserAnimationsModule,
    SharedUiMessageModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
