import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageShellComponent } from './components/page-shell/page-shell.component';

import { CrowdfundingUiNavigationModule } from '@equito/crowdfunding/ui/navigation';
import { CrowdfundingUiFooterModule } from '@equito/crowdfunding/ui/footer';

@NgModule({
  imports: [
    CommonModule,
    CrowdfundingUiNavigationModule,
    CrowdfundingUiFooterModule,
  ],
  declarations: [PageShellComponent],
  exports: [PageShellComponent],
})
export class CrowdfundingUiPageShellModule {}
