import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'equito-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  constructor(private cdRef: ChangeDetectorRef) {}
  @Input() value = 0;
  @Input() max = 1;

  get coverWidth() {
    return (this.value / this.max) * 100;
  }

  get percentage() {
    return Math.floor(this.coverWidth);
  }

  get progressBarValueRightOffset() {
    let offset = 100 - this.coverWidth;

    if (offset < 0) {
      offset = 0;
    }

    return offset > 85 ? null : offset;
  }

  get progressBarValueLeftOffset() {
    const offset = this.coverWidth < 0 ? 0 : this.coverWidth;
    return offset > 15 ? null : offset;
  }
}
