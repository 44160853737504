import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private toastr: ToastrService) {}

  success(value: string) {
    this.toastr.success(value);
  }

  error(value: string) {
    this.toastr.error(value);
  }
}
