import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { MenuItem } from '@equito/shared/models';
import * as equal from 'fast-deep-equal';

@Injectable({
  providedIn: 'root',
})
export class NavigationDataService {
  data$ = new BehaviorSubject(null);
  menuItem$: Observable<MenuItem> = this.data$.pipe(
    map((data) => (data && data.menuItem) || null),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    const mergeDataProp = (node) => {
      return {
        ...node.data,
        ...(node.firstChild ? mergeDataProp(node.firstChild) : {}),
      };
    };

    const curentStateData$ = this.router.events.pipe(
      filter((x) => x instanceof NavigationEnd),
      tap(() => {
        const data = mergeDataProp(this.activatedRoute.snapshot);
        if (!equal(data, this.data$.value)) {
          this.data$.next(data);
        }
      })
    );

    curentStateData$.subscribe();
  }
}
