import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { NavigationComponent } from './components/navigation/navigation.component';

@NgModule({
  imports: [CommonModule, MatRippleModule, RouterModule],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class CrowdfundingUiNavigationModule {}
