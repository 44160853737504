<nav class="navigation" [class.navigation--overlay]="isOverlayVisible$ | async">
  <div class="container">
    <div
      class="row mt-6 align-items-center"
      [class.justify-content-between]="(isOverlayVisible$ | async) === false"
      [class.align-content-center]="isOverlayVisible$ | async"
    >
      <button
        matRipple
        class="hamburger-menu-btn col-auto"
        [class.hamburger-menu-btn--hidden-for-large-screen]="
          (isOverlayVisible$ | async) === false
        "
        [class.hamburger-menu-btn--active]="isOverlayVisible$ | async"
        (click)="toggleOverlayVisibility()"
      >
        <div class="hamburger-menu-btn__line"></div>
        <div class="hamburger-menu-btn__line"></div>
        <div class="hamburger-menu-btn__line"></div>
      </button>

      <img
        *ngIf="(isOverlayVisible$ | async) === false"
        class="navi-logo col-auto"
        src="assets/images/equito-logo-white.svg"
      />
      <img
        *ngIf="isOverlayVisible$ | async"
        class="navi-logo navi-logo--center col-auto"
        src="assets/images/equito-logo-dark.svg"
      />
      <ng-container *ngIf="(isOverlayVisible$ | async) === false; then links">
      </ng-container>

      <div
        class="col-12 d-flex flex-column align-items-center mt-8 landing-hide"
        *ngIf="isOverlayVisible$ | async"
        (click)="closeOverlay()"
      >
        <ng-container *ngTemplateOutlet="links"> </ng-container>
        <a matRipple class="btn btn--primary mt-4">Start Investing</a>
      </div>

      <div
        *ngIf="(isOverlayVisible$ | async) === false"
        class="col-auto landing-hide"
      >
        <a matRipple class="btn btn--primary">Start Investing</a>
      </div>
    </div>
  </div>
</nav>

<ng-template #links>
  <a
    *ngFor="let page of pages"
    class="nav-link col-auto landing-hide"
    [class.nav-link--mobile]="isOverlayVisible$ | async"
    [class.nav-link--active]="page.menu === (currentMenu$ | async)"
    [routerLink]="page.routerLink"
  >
    {{ page.title }}
  </a>
</ng-template>
