import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'equito-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  disabled = false;
  value = false;

  onChange: (value?: boolean) => void = () => {
    // NOOP
  };
  onTouched = () => {
    // NOOP
  };

  writeValue(value: boolean): void {
    this.value = value;
  }

  onInputChange(value: boolean): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
