<div id="equito-footer-ac">
  <div class="_form_5"></div>
  <equito-checkbox id="footerCheckbox" class="ml-9 pb-5 white">
    <span class="paragraph paragraph--white paragraph--small"
      >I agree with the
    </span>
    <a
      class="link link--black link--underline link--biger-font"
      target="_blank"
      href="/assets/landing-page/Equito_privacy_policy.pdf"
    >
      privacy policy.</a
    >
  </equito-checkbox>
</div>
