import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '@equito/shared/models';
import { NavigationDataService } from '@equito/shared/router/navigation-data';

@Component({
  selector: 'equito-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  pages = [
    {
      title: 'Explore projects',
      routerLink: '/explore-projects',
      menu: MenuItem.ExploreProjects,
    },
    {
      title: 'Learn to Invest',
      routerLink: '/learn-to-invest',
      menu: MenuItem.LearnToInvest,
    },
    {
      title: 'About',
      routerLink: '',
      menu: MenuItem.About,
    },
    {
      title: 'FAQ',
      routerLink: '',
      menu: MenuItem.FAQ,
    },
    {
      title: 'Raise Capital',
      routerLink: '',
      menu: MenuItem.RaiseCapital,
    },
    {
      title: 'Log in',
      routerLink: '/dashboard',
      menu: MenuItem.Dashboard,
    },
  ];

  currentMenu$ = this.navigationDataService.menuItem$;

  isOverlayVisible$ = new BehaviorSubject<boolean>(false);

  constructor(private navigationDataService: NavigationDataService) {}

  toggleOverlayVisibility() {
    this.isOverlayVisible$.next(!this.isOverlayVisible$.value);
  }

  closeOverlay() {
    if (this.isOverlayVisible$.value) {
      this.isOverlayVisible$.next(false);
    }
  }
}
