<a [routerLink]="link" class="investment-card">
  <img [src]="investment.backgroundSmall" />
  <div class="investment-card__wrapper">
    <div class="investment-card__row">
      <strong class="investment-card__title"> {{ investment.name }} </strong>
      <div class="investment-card__logo">
        <img [src]="investment.logo" />
      </div>
    </div>
    <p class="mt-4 paragraph paragraph--small paragraph--grey">
      {{ investment.description }}
    </p>
    <equito-progress-bar
      class="mt-4"
      [value]="investment.progress"
    ></equito-progress-bar>
    <div class="row mt-5">
      <div class="col">
        <equito-label class="grey"> Raised </equito-label>
        <equito-value class="mt-2">
          {{ investment.raised | currency: 'EUR':'symbol':'1.0-0' }}
        </equito-value>
        <equito-label class="green mt-2">{{
          investment.progress | percent
        }}</equito-label>
      </div>
      <div class="col">
        <equito-label class="grey">Target</equito-label>
        <equito-value class="mt-2">
          {{ investment.target | currency: 'EUR':'symbol':'1.0-0' }}
        </equito-value>
        <equito-label class="grey mt-2">
          {{ investment.timeLeft }} days left
        </equito-label>
      </div>
      <div class="col">
        <equito-label class="grey">Investors</equito-label>
        <equito-value class="mt-2">
          {{ investment.investorsCount | number }}
        </equito-value>
      </div>
    </div>
  </div>
</a>
