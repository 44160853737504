<div
  class="progress-bar-cover"
  [class.progress-bar-cover--round-left-corner]="coverWidth === 0"
  [style.left.%]="coverWidth"
></div>
<span
  class="progress-bar-value"
  [style.right.%]="progressBarValueRightOffset"
  [style.left.%]="progressBarValueLeftOffset"
>
  {{ percentage }}%
</span>
