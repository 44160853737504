<div class="checkbox">
  <label class="checkbox__label">
    <input
      [disabled]="disabled"
      type="checkbox"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
    />
    <div><ng-content></ng-content></div>
  </label>
</div>
