import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedUiCheckboxModule } from '@equito/shared/ui/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ActiveCampaignFooterCardComponent } from './active-campaign-footer-card/active-campaign-footer-card.component';
import { ActiveCampaignRaiseCapitalComponent } from './active-campaign-raise-capital/active-campaign-raise-capital.component';
import { ActiveCampaignInvestorsComponent } from './active-campaign-investors/active-campaign-investors.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiCheckboxModule,
    HttpClientModule,
    RouterModule,
  ],
  declarations: [
    ActiveCampaignFooterCardComponent,
    ActiveCampaignRaiseCapitalComponent,
    ActiveCampaignInvestorsComponent,
  ],
  exports: [
    ActiveCampaignFooterCardComponent,
    ActiveCampaignInvestorsComponent,
    ActiveCampaignRaiseCapitalComponent,
  ],
})
export class CrowdfundingUiActiveCampaignModule {}
