import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
const scriptUrl = 'https://equito.activehosted.com/f/embed.php?id=7';
const scriptId = 'formid7';

@Component({
  selector: 'equito-active-campaign-raise-capital',
  templateUrl: './active-campaign-raise-capital.component.html',
  styleUrls: ['./active-campaign-raise-capital.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveCampaignRaiseCapitalComponent implements OnInit, OnDestroy {
  waitForWidgetToFullyLoad;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    clearInterval(this.waitForWidgetToFullyLoad);
    const el = this.document.getElementById(scriptId);

    if (el) {
      this.renderer.removeChild(this.document, el);
    }
  }

  ngOnInit(): void {
    const el = this.renderer.createElement('script');
    this.renderer.setAttribute(el, 'type', 'text/javascript');
    this.renderer.setAttribute(el, 'src', scriptUrl);
    this.renderer.setAttribute(el, 'id', scriptId);
    this.renderer.setProperty(el, 'async', true);
    this.renderer.setProperty(el, 'defer', true);
    this.renderer.appendChild(this.document.body, el);

    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'stylesheet');
    this.renderer.setAttribute(
      link,
      'href',
      '/assets/css/active-campaing-raise-capital.css'
    );
    this.renderer.setAttribute(link, 'tyle', 'text/css');
    this.renderer.setProperty(link, 'async', true);
    this.renderer.setProperty(link, 'defer', true);
    this.renderer.appendChild(this.document.head, link);

    this.waitForWidgetToFullyLoad = setInterval(() => {
      const widgetFrame = this.document.getElementById(
        'equito-raise-capital-ac'
      );
      const form = widgetFrame?.getElementsByTagName('form')[0];
      if (form) {
        const formWidget = this.document.getElementsByClassName(
          '_form_7'
        )[0] as HTMLElement;
        formWidget.children[0].remove();

        form.classList.add('ac-raise-capital-form');

        const formContent = form.getElementsByClassName(
          '_form-content'
        )[0] as HTMLElement;
        if (formContent) {
          formContent.classList.add('ac-raise-capital-form-content');
        }

        const inputFormWrapperElement = formContent.children[0] as HTMLElement;
        if (inputFormWrapperElement) {
          inputFormWrapperElement.classList.add(
            'ac-raise-capital-form-wrapper-input'
          );
        }

        const inputField = formContent.querySelector(
          'input[id="email"]'
        ) as HTMLInputElement;
        if (inputField) {
          inputField.setAttribute('autocomplete', 'off');
          inputField.classList.add('ac-raise-capital-form-input');
        }

        const buttonFormWrapperElement = formContent.children[1] as HTMLElement;
        if (buttonFormWrapperElement) {
          buttonFormWrapperElement.classList.add(
            'ac-raise-capital-form-wrapper-button'
          );
        }

        const button = formContent.getElementsByTagName(
          'button'
        )[0] as HTMLButtonElement;

        const checkbox = this.document
          .getElementById('raiseCapitalCheckbox')
          .querySelector('input[type="checkbox"]') as HTMLElement;

        if (button) {
          button.classList.add('btn');
          button.classList.add('btn--primary');
          button.classList.add('ac-raise-capital-form-button');
          button.setAttribute('disabled', 'true');

          button?.addEventListener('click', () => {
            const validateEmail = (email) => {
              return email.match(
                // eslint-disable-next-line
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
            };

            if (validateEmail(inputField.value)) {
              checkbox.parentElement.style.display = 'none';

              const url = this.router.serializeUrl(
                this.router.createUrlTree([
                  '/assets/landing-page/Equito_case_study.pdf',
                ])
              );
              window.open(url, '_blank');
            }
          });

          checkbox.addEventListener('change', () => {
            const checkboxStatus = checkbox.getAttribute('ng-reflect-model');
            if (checkboxStatus === 'true') {
              button.removeAttribute('disabled');
            } else {
              button.setAttribute('disabled', 'true');
            }
          });
        }

        const thanksForSigningUp = form.getElementsByClassName(
          '_form-thank-you'
        )[0] as HTMLElement;
        if (thanksForSigningUp) {
          thanksForSigningUp.classList.add('ac-raise-capital-thank-you');
        }

        const label = formContent.getElementsByClassName('_form-label')[0];
        label.remove();
        clearInterval(this.waitForWidgetToFullyLoad);
      }
    }, 25);
  }
}
