<div class="footer">
  <div class="container">
    <img class="footer__logo" src="assets/images/equito-logo-dark.svg" />
    <div class="row">
      <div class="col-lg-6">
        <span class="footer__text">
          Connecting startup businesses & investors.
        </span>
      </div>
      <div class="col-lg-6 col-sm-12 mt-5">
        <div class="social-networks mt-5">
          <a
            class="mr-0 mr-lg-7"
            target="_blank"
            href="https://linkedin.com/company/equitoco"
            ><mat-icon svgIcon="eq-linkedin-landing"></mat-icon
          ></a>
          <a
            class="mr-0 mr-lg-7"
            target="_blank"
            href="https://twitter.com/EquitoCo"
            ><mat-icon svgIcon="eq-twitter-landing"></mat-icon
          ></a>
        </div>
      </div>
    </div>
    <div class="row mt-9 mb-sm-11">
      <div class="col-md-6">
        <div class="row landing-hide">
          <ul class="mt-4 col-6 col-sm-4">
            <li class="footer__links-header">Get started</li>
            <li class="mt-4">
              <a routerLink="/" class="link footer__link"> Explore projects </a>
            </li>
            <li class="mt-2">
              <a href="#" class="link footer__link">Learn to Invest</a>
            </li>
            <li class="mt-2">
              <a href="#" class="link footer__link">Raise Capital</a>
            </li>
          </ul>
          <ul class="mt-4 col-6 col-sm-4">
            <li class="footer__links-header">Help</li>
            <li class="mt-4">
              <a href="#" class="link footer__link">About</a>
            </li>
            <li class="mt-4">
              <a href="#" class="link footer__link">FAQ</a>
            </li>
            <li class="mt-2">
              <a href="#" class="link footer__link">News</a>
            </li>
            <li class="mt-2">
              <a href="#" class="link footer__link">Contact us</a>
            </li>
          </ul>
          <ul class="mt-4 col-6 col-sm-4">
            <li class="footer__links-header">Account</li>
            <li class="mt-4">
              <a href="#" class="link footer__link">Log in</a>
            </li>
            <li class="mt-4">
              <a href="#" class="link footer__link">Start investing</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <equito-active-campaign-footer-card
          class="active-campaign-padding"
        ></equito-active-campaign-footer-card>
      </div>
    </div>
    <div class="row footer__copyright-row">
      <div class="col-12 col-lg-auto footer__copyright">
        &copy; {{ year$ | async }} Equito. All rights reserved.
      </div>
      <a
        class="col col-lg-auto footer__link footer__link--dark"
        target="_blank"
        href="/assets/landing-page/Equito_privacy_policy.pdf"
      >
        Privacy Policy
      </a>
    </div>
  </div>
</div>
