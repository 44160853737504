import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'equito-page-shell',
  templateUrl: './page-shell.component.html',
  styleUrls: ['./page-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageShellComponent {
  @Input() landingPage = false;
}
