import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedUiProgressBarModule } from '@equito/shared/ui/progress-bar';
import { SharedUiLabelModule } from '@equito/shared/ui/label';
import { SharedUiValueModule } from '@equito/shared/ui/value';
import { InvestmentCardComponent } from './investment-card/investment-card.component';
import {
  InvestmentCardConfigToken,
  InvestmentCardModuleConfig,
} from './investment-card-config-token';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedUiProgressBarModule,
    SharedUiLabelModule,
    SharedUiValueModule,
  ],
  declarations: [InvestmentCardComponent],
  exports: [InvestmentCardComponent],
})
export class CrowdfundingUiInvestmentCardModule {
  static forRoot(
    config: InvestmentCardModuleConfig
  ): ModuleWithProviders<CrowdfundingUiInvestmentCardModule> {
    return {
      ngModule: CrowdfundingUiInvestmentCardModule,
      providers: [
        {
          provide: InvestmentCardConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
